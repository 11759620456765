import React, { useEffect, useRef } from 'react';
interface SvgSpriteRendererProps {
    svgContent: string;
}
const SvgSpriteRenderer: React.FC<SvgSpriteRendererProps> = ({ svgContent }) => {
    const divRef = useRef<HTMLDivElement | null>(null);
    useEffect(() => {
        if (divRef.current) {
            divRef.current.innerHTML = svgContent;
        }
    }, [svgContent]);
    return <div ref={divRef} />;
};
export default SvgSpriteRenderer;
