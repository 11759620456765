import { NumberBox, SelectBox } from 'devextreme-react';
import React, { useEffect, useReducer } from 'react';
import { useParams } from 'react-router-dom';
import { APP_URLS } from '../../appUrls';
import Button from "../../components/button/button";
import Icon, { IconEnum } from '../../components/icon';
import OuterLink from '../../components/outerLink/outerLink';
import Spinner, { ClipSpinner } from '../../components/spinner';
import { ClientApplication } from '../../msal/clientApp';
import { MinimumLicensesNeededDto, StripeIntegrationClient, SupportedBillingIntervalDtoEnum } from '../../services/service';
import { BillingAction, billingIntervalOptions, billingReducer, initialBillingState } from './billing.reducer';
import { BillingFooter } from './billingFooter';

import appInsights from '../../common/applicationInsights';
import TrackedEventProcessEnum from '../../common/trackedEventProcessEnum';
import TrackedEventTypeEnum from '../../common/trackedEventTypeEnum';
import { tooltipRender } from '../../components/dxComponents/itemRenderers';

import classNames from 'classnames';
import './billing.scss';

// For now, we are supporting only these 3 currencies
const currencyDictionary = {
    "usd": {
        name: "USD - US Dollar ($)",
        sign: "$",
        key: "usd"
    },
    "gbp": {
        name: "GBP - British Pound (£)",
        sign: "£",
        key: "gbp"
    },
    "eur": {
        name: "EUR - Euro (€)",
        sign: "€",
        key: "eur"
    },
};

const createBillingIntervalOptions = (supportedBillingInterval: SupportedBillingIntervalDtoEnum) => {
    return billingIntervalOptions.filter(x => (x.interval & supportedBillingInterval) === x.interval)
}
interface BillingProps {
    minimumLicensesNeededInfo: MinimumLicensesNeededDto | undefined;
}
const billing: React.FC<BillingProps> = ({ minimumLicensesNeededInfo }) => {
    const { subscriptionId } = useParams() as any;

    const [state, dispatch] = useReducer(billingReducer, initialBillingState);

    const { billingInterval, currency, isFetchingCustomerInfo, isFetchingPaymentLink, isFetchingPlans,
        isFetchingPricing, numberOfUsers, customerInfo, errorFetchingCustomerInfo, errorFetchingPaymentLink,
        errorFetchingPlans, errorFetchingPricing, paymentLink, plans, pricing, selectedPlan, unitPrice,
        isPaymentLinkCopiedToClipboard } = state;

    // Initially, fetch all plans and customer information
    useEffect(
        () => {
            getPlans(dispatch);
            getCustomerInfo(dispatch);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    )

    // When the selected plan, billing interval, or the selected currency is changed -> fetch new pricing
    useEffect(
        () => {
            if (!selectedPlan || !billingInterval || !currency) {
                return;
            }

            getPricing(
                dispatch,
                selectedPlan.stripeId,
                currency,
                billingInterval.key);
        },
        [selectedPlan, billingInterval, currency]
    )

    if (isFetchingPlans || isFetchingCustomerInfo) {
        return <Spinner text='Loading...' style={{ height: 400, width: '80%', minWidth: '400px' }}>
            <ClipSpinner size={70} color={'var(--electric-indigo)'} />
        </Spinner>;
    }

    const onSelectedPlanChanged = (e) => {
        dispatch({ type: 'set-selected-plan', payload: plans![e.value] });
    }

    const onNumberOfUsersChange = (e) => {
        dispatch({ type: 'set-number-of-users', payload: e.component.option('value') as number });
    }

    const onBillingIntervalChanged = (e) => {
        dispatch({ type: 'set-billing-interval', payload: billingIntervalOptions.find(x => x.key === e.value)! });
    }

    const onCurrencyChanged = (e) => {
        dispatch({ type: 'set-currency', payload: e.value });
    }

    const onBuyNowClicked = async () => {
        if (isFetchingPaymentLink || paymentLink) {
            return;
        }

        const link = await createPaymentLink(
            dispatch,
            subscriptionId,
            numberOfUsers,
            customerInfo?.stripeId,
            pricing!.stripeId);

        if (link) {
            appInsights.trackEvent("Buy Now Clicked",
                TrackedEventTypeEnum.BuyNowClicked,
                TrackedEventProcessEnum.SubscriptionBillingProcess);

            window.open(link, '_self', 'noopener,noreferrer');
        }
    }

    const onCopyOrderLinkClicked = async () => {
        if (isFetchingPaymentLink || paymentLink) {
            return;
        }

        const link = await createPaymentLink(
            dispatch,
            subscriptionId,
            numberOfUsers,
            customerInfo?.stripeId,
            pricing!.stripeId);

        if (link) {
            navigator.clipboard.writeText(link);
            dispatch({ type: 'payment-link-copied-to-clipboard', payload: true });
        }
    }

    const planOptions = Object.keys(plans!).map((planId) => { return { name: plans![planId].name, key: plans![planId].stripeId }; })

    const error = errorFetchingCustomerInfo ?? errorFetchingPaymentLink ?? errorFetchingPlans ?? errorFetchingPricing;

    const minimumLicensesAllowed = 100;
    const minimumLicensesNeeded = Math.max(Number(minimumLicensesNeededInfo?.licensedUserCount) | 0, minimumLicensesAllowed); // Math.max(NaN, 100) === NaN

    const isValidNumberOfUsers = numberOfUsers >= minimumLicensesNeeded;

    const isLessThanMinimumLicenses = (numberOfUsers < minimumLicensesAllowed) && minimumLicensesNeeded < minimumLicensesAllowed;

    const getMinimumLicenseNeededCount = Math.max(minimumLicensesNeeded, minimumLicensesAllowed);

    const numberOfUsersLabel = isValidNumberOfUsers ? `${numberOfUsers.toLocaleString()} users` : 'N/A';
    const totalPriceLabel = !isFetchingPricing && unitPrice
        ? (isValidNumberOfUsers ? `${currencyDictionary[currency].sign}${calculatePrice(unitPrice, numberOfUsers).toLocaleString()}` : 'N/A')
        : "Loading...";

    const price = !isFetchingPricing && unitPrice && billingInterval && currency
        ? `${currencyDictionary[currency].sign}${unitPrice} per user/${billingInterval.key}`
        : '';

    const taxInterval = billingInterval && billingInterval.interval === SupportedBillingIntervalDtoEnum.Annually ? 'year' : 'month';

    return (
        <div className="billing">
            <div className="specify-plan">
                <div className="select-plan">
                    <div className="row">
                        <div className="plan-type">
                            <h3>Select a Plan</h3>
                            <SelectBox
                                value={selectedPlan?.stripeId}
                                className='input'
                                dataSource={planOptions}
                                displayExpr={"name"}
                                valueExpr={"key"}
                                hoverStateEnabled={false}
                                dropDownOptions={{ maxHeight: '40vh', width: '30vh' }}
                                onValueChanged={onSelectedPlanChanged}
                                itemRender={tooltipRender('name')}
                            />
                            <p>{price}</p>
                            <OuterLink icon href={APP_URLS.comparePlans}>Compare plans</OuterLink>
                        </div>
                        <div className="billing-cycle">
                            <h3>Billing Cycle</h3>
                            <SelectBox
                                dataSource={createBillingIntervalOptions(selectedPlan!.supportedBillingInterval)}
                                value={billingInterval.key}
                                className='input'
                                displayExpr={"name"}
                                valueExpr={"key"}
                                hoverStateEnabled={false}
                                onValueChanged={onBillingIntervalChanged}
                                dropDownOptions={{ maxHeight: '40vh' }}
                            />
                        </div>
                        {
                            !customerInfo?.currency &&
                            <div className="currency">
                                <h3>Currency</h3>
                                <SelectBox
                                    dataSource={Object.values(currencyDictionary)}
                                    value={currency}
                                    className='input'
                                    displayExpr={"name"}
                                    valueExpr={"key"}
                                    hoverStateEnabled={false}
                                    onValueChanged={onCurrencyChanged}
                                    dropDownOptions={{ maxHeight: '40vh' }}
                                />
                            </div>
                        }
                    </div>
                    <div>
                        <div className="users-input">
                            <h3>Number of Licenses</h3>
                            <NumberBox
                                value={numberOfUsers}
                                min={1}
                                max={1000000000}
                                onKeyUp={onNumberOfUsersChange}
                                valueChangeEvent="keyup"
                                className={classNames('input-box', isValidNumberOfUsers ? '' : 'invalid')}
                            />
                            <div className="license-message">
                                {!isValidNumberOfUsers && <Icon iconName={IconEnum.AlertTriangle} width='14' height='14' className='alert-icon'></Icon>}
                                {!isLessThanMinimumLicenses && <div><p>You need to purchase <b>{getMinimumLicenseNeededCount}</b> licenses.</p></div>}
                                {isLessThanMinimumLicenses && <div><p>Minimum number of licenses you can purchase is 100.</p></div>}
                                <OuterLink icon href={APP_URLS.licensedUsersCount}>How we calculate licensing</OuterLink>
                            </div>
                        </div>
                    </div>
                </div>

                {error && <p className='error'>Error! {error}</p>}
                <BillingFooter />
            </div>
            {
                selectedPlan && billingInterval && currency &&
                <div className="pricing-details">
                    <div className="top">
                        <h3 className='title'>Summary</h3>
                        <p className='pricing-item'>{selectedPlan?.name}</p>
                        <div className="row">
                            <span>{numberOfUsersLabel}</span>
                            <span>{totalPriceLabel}</span>
                        </div>
                        <hr />
                        <div className="row">
                            <span className='pricing-item'>Total</span>
                            <b>{totalPriceLabel}</b>
                        </div>

                        <span>+ tax / {taxInterval}</span>
                    </div>
                    <div className="footer">
                        <Button
                            className='buy-button'
                            onClick={onBuyNowClicked}
                            disabled={isFetchingPaymentLink || !isValidNumberOfUsers}
                            loading={isFetchingPaymentLink}
                        >
                            Buy Now
                        </Button>
                        <Button
                            textual
                            className='copy-link-button'
                            onClick={onCopyOrderLinkClicked}
                            disabled={isFetchingPaymentLink || (!!paymentLink)}
                        >
                            {isPaymentLinkCopiedToClipboard
                                ? <>Link copied to clipboard!</>
                                : <><Icon iconName={IconEnum.Copy} />&nbsp;Copy order link</>
                            }

                        </Button>
                    </div>
                </div>
            }
        </div>
    );
}

const calculatePrice = (unitPrice: number, numberOfUsers: number) => {
    return Math.round((unitPrice * numberOfUsers + Number.EPSILON) * 100) / 100;
}

const getPlans = async (dispatch: (value: BillingAction) => void) => {
    dispatch({ type: 'get-plans', payload: { isLoading: true } });

    try {
        const stripeIntegrationClient = new StripeIntegrationClient();
        const plansResponse = await stripeIntegrationClient.getAllPlans();

        dispatch({ type: 'get-plans', payload: { data: plansResponse } });
    } catch (error) {
        console.error(error);
        dispatch({ type: 'get-plans', payload: { error: error.message } });
    }
};

const getCustomerInfo = async (dispatch: (value: BillingAction) => void) => {
    dispatch({ type: 'get-customer-info', payload: { isLoading: true } });

    try {
        const stripeIntegrationClient = new StripeIntegrationClient();
        const account = ClientApplication.instance.getActiveAccount();
        const customerInfoResponse = await stripeIntegrationClient.getCustomerInfo(account!.idTokenClaims!.email as string);

        dispatch({ type: 'get-customer-info', payload: { data: customerInfoResponse } });
    } catch (error) {
        // when 204 (no content) do nothing
        if (error.status == 204) {
            dispatch({ type: 'get-customer-info', payload: { data: undefined } });
            return;
        }

        console.error(error);
        dispatch({ type: 'get-customer-info', payload: { error: error.message } });
    }
}

const getPricing = async (dispatch: (value: BillingAction) => void, planId: string, currency: string, billingInterval: string) => {
    dispatch({ type: 'get-pricing', payload: { isLoading: true } });

    try {
        const stripeIntegrationClient = new StripeIntegrationClient();
        const pricesResponse = await stripeIntegrationClient.getPrices(
            planId,
            currency,
            billingInterval);

        dispatch({ type: 'get-pricing', payload: { data: pricesResponse } });
    } catch (error) {
        console.error(error);
        dispatch({ type: 'get-pricing', payload: { error: error.message } });
    }
}

const createPaymentLink = async (
    dispatch: (value: BillingAction) => void,
    subscriptionId: string,
    numberOfUsers: number,
    customerStripeId: string | undefined,
    pricingId: string
) => {
    dispatch({ type: 'create-payment-link', payload: { isLoading: true } });

    try {
        const stripeIntegrationClient = new StripeIntegrationClient();
        const account = ClientApplication.instance.getActiveAccount();
        const paymentLinkResponse = await stripeIntegrationClient.createPaymentLink(
            {
                subscriptionId,
                quantity: numberOfUsers,
                customerEmail: account?.idTokenClaims?.email as string,
                stripeCustomerId: customerStripeId,
                priceId: pricingId,
                customerId: account?.localAccountId,
                cancelUrl: window.location.href,
                successUrl: window.origin
            });

        dispatch({ type: 'create-payment-link', payload: { data: paymentLinkResponse } });
        return paymentLinkResponse;
    } catch (error) {
        console.error(error);
        dispatch({ type: 'create-payment-link', payload: { error: error.message } });
    }

    return undefined;
}

export default billing;