import React, { useState } from 'react';
import appInsights from '../../common/applicationInsights';
import TrackedEventProcessEnum from '../../common/trackedEventProcessEnum';
import TrackedEventTypeEnum from '../../common/trackedEventTypeEnum';
import Button, { ButtonProps } from '../../components/button/button';
import { StripeIntegrationClient } from '../../services/service';
import { onExternalLinkClickCallback } from '../../utils/url';

type CustomersPortalButtonProps = Omit<ButtonProps, 'onClick' | 'loading'> & { subscriptionId: string }

export const CustomersPortalButton: React.FC<CustomersPortalButtonProps> = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<Error>()

    const onClick = async () => {
        const stripeIntegrationClient = new StripeIntegrationClient();
        setError(undefined);
        try {
            setIsLoading(true);
            const url = await stripeIntegrationClient.openCustomerPortal(
                {
                    returnUrl: window.location.href
                }, props.subscriptionId);

            appInsights.trackEvent("Manage Stripe Subscription Clicked",
                TrackedEventTypeEnum.ManageStripeSubscription,
                TrackedEventProcessEnum.SubscriptionBillingProcess);

            onExternalLinkClickCallback(url);
            setIsLoading(false);
        } catch (e) {
            console.error(e);
            setError(e);
            setIsLoading(false);
        }
    }

    return (
        <Button onClick={onClick} loading={isLoading} error={error} {...props} />
    );
}